<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input" style="width: 230px;" prefix="会员名" placeholder="请输入会员姓名" v-model="form.nick_name" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" prefix="手机号" style="width: 230px;" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
					</div>
					<div class="mt20">会员生日</div>
					<div class="mt10">
					  <a-date-picker  valueFormat="YYYY-MM-DD" v-model="form.birthday"/>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					nick_name:'',
					mobile:'',
					birthday:'',
				}
			}
		},
		computed:{
			getTitle(){
				if(this.member_id==0){
					return "添加会员";
				}else{
					return "编辑会员"
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancelEditMember");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveMember',{
					nick_name:this.form.nick_name,
					mobile:this.form.mobile,
					birthday:this.form.birthday
				}).then(res=>{
					this.$message.success('添加成功',1,()=>{
						this.confirmLoading=false;
						this.refreshPage('/member'); // 如果在很多地方使用的组件 表单操作成功后要去到对应的页面 就可以调用 refreshPage
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>
