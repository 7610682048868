<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
					<!-- <div>
						<a-button style="width: 160px;" icon="plus" type="primary" @click="addMemberAct()">添加会员</a-button>
					</div>
					<div class="mt16">
						<a-button style="width: 160px;" type="primary" ghost @click="contactUs()"><i class="iconfont iconimport mr5"></i>导入会员</a-button>
					</div> -->
					<div class="mt20">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>

					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
		<div v-if="addMemberLoading">
			<add-member :visible="addMemberLoading" @cancelEditMember="cancelAddMember" @okEditMember="okAddMember"></add-member>
		</div>
		
		<div v-if="contactUsLoading">
			<contact-us :visible="contactUsLoading" @cancel="cancelContactUs"></contact-us>
		</div>
	</div>
</template>

<script>
	import addMember from './components/index/modal/editMember.vue';
	import contactUs from '../miniapp/components/payManage/servicePay.vue';
	export default {
		components:{
			addMember,
			contactUs,
		},
		data() {
			return {
				addMemberLoading:false,
				contactUsLoading:false,
				menus: [{
						name: '会员列表',
						icon: 'iconclient_tab01',
						key: 'member_index',
						link: '/member'
					},
					// {
					// 	name: '会员等级',
					// 	icon: 'iconclient_tab02',
					// 	key: 'member_rank',
					// 	link: '/member/rank'
					// },
					{
						name: '积分记录',
						icon: 'iconclient_tab03',
						key: 'member_integral',
						link: '/member/integral'
					},
					{
						name: '余额记录',
						icon: 'iconclient_tab04',
						key: 'member_money',
						link: '/member/money'
					},
					{
						name: '充值记录',
						icon: 'iconclient_tab03',
						key: 'member_chongzhi',
						link: '/member/chongzhi'
					},
					{
						name: '消费记录',
						icon: 'iconclient_tab04',
						key: 'member_xiaofei',
						link: '/member/xiaofei'
					},
					{
						name: '洗车订单',
						icon: 'iconmall_order',
						key: 'member_washorder',
						link: '/member/washorder'
					},
					// {
					// 	name: '线下补单',
					// 	icon: 'iconmall_budan',
					// 	key: 'member_supplement',
					// 	link: '/member/supplement'
					// },
					// {
					// 	name: '买单记录',
					// 	icon: 'iconmaidan',
					// 	key: 'member_buy',
					// 	link: '/member/buy'
					// },
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
			addMemberAct(){
				this.addMemberLoading=true;
			},
			cancelAddMember(){
				this.addMemberLoading=false;
			},
			okAddMember(){
				this.addMemberLoading=false;
			},
			
			contactUs(){
				this.contactUsLoading=true;
			},
			cancelContactUs(){
				this.contactUsLoading=false;
			}
			
		}
	}
</script>

<style>
	
</style>
